td {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
}

.clickable-row {
    cursor: pointer;
}

.clickable-row:hover {
    background-color: rgba(33, 37, 41, 0.075);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: white;
    background-color: black;
}

.nav-link {
    color: #777;
}

.nav-link:hover {
    color: white;
    background-color: black;
}
